
import Vue from 'vue'
import { getSubDomainName } from "@/assets/js/common.js"
import RootToolsMixin from 'GroomyRoot/mixins/Tools.js'

export default Vue.util.mergeOptions(RootToolsMixin, {
    data() {
        return {
            regex_alpha: /(\w)/,
            regex_alpha_nc: /(\W)/, // Exclu les accents et apostrophes
            regex_alpha_fr: /([A-Za-z0-9èÉéÈêÊëË'" ])/,
            regex_alpha_fr_nc: /([^A-Za-z0-9èÉéÈêÊëË'". ])/,

            // regex_sire: /(?<![0-9]{1,})([0-9]{8}[A-Z])(?![A-Z]{1,})/, // match un sire complet, on cherche s'il y a d'autre chiffres avant, et d'autres lettres qui suivent la clé, si oui, la regex est rejetée => (?!) = négation
            regex_sire: /^[0-9]{8}[A-Z]$/, // match un sire complet, on cherche s'il y a d'autre chiffres avant, et d'autres lettres qui suivent la clé, si oui, la regex est rejetée => (?!) = négation
            regex_sire_like: /([0-9]{5})/, // regex permettant de commencer la recherche de sire
            // regex_incomplete_sire_car: /(?<![0-9]{1,})([0-9]{8})([A-Z]{2,}$)/, // match 8 chiffres et plusieurs lettre => Erreur de saisie, pas possible d'avoir plusieurs lettres
            regex_incomplete_sire_car: /^[0-9]{8}[A-Z]{2,}$/, // match 8 chiffres et plusieurs lettre => Erreur de saisie, pas possible d'avoir plusieurs lettres
            // regex_incomplete_sire_num: /(?<![0-9]{1,})([0-9]{6,7}[A-Z])/, // match entre 6 et 7 chiffres et une lettre => Un chiffre est manquant
            regex_incomplete_sire_num: /^[0-9]{6,7}[A-Z]$/, // match entre 6 et 7 chiffres et une lettre => Un chiffre est manquant
            // regex_incomplete_sire_cle: /([0-9]{8})(?![A-Z]{1,})/, // match entre 8 chiffres => Un caractère est manquant (la clé)
            regex_incomplete_sire_cle: /^[0-9]{8}$/, // match entre 8 chiffres => Un caractère est manquant (la clé)

            // regex_ueln: /(?<![0-9]{1,})([0-9]{14}[A-Z])(?![A-Z]{1,})/, // match un ueln complet, on cherche également s'il n'y a pas d'autre chiffres avant, et d'autres lettres qui suivent la clé
            regex_ueln: /^(([0-9]{14})|(([0-9]{4}[A-Z]{2}[0-9]{8})|([0-9]{3}[A-Z]{3}[0-9]{8}))[A-Z]$)|^(([0-9]{9}[A-Z][0-9]{5}$)|([0-9]{10}[A-Z][0-9]{4}$))/, // match un ueln complet, on cherche également s'il n'y a pas d'autre chiffres avant, et d'autres lettres qui suivent la clé
            regex_ueln_like: /([0-9]{9})|([0-9]{3}[A-Z]{3})|([0-9]{4}[A-Z]{2})/, // regex permettant de commencer la recherche d'ueln
            regex_ueln_error: /([0-9]{15,}|[A-Z]{2,})/, // regex permettant de commencer la recherche d'ueln
            regex_incomplete_ueln_car: /(([0-9]{15,}|[0-9]{14,})[A-Z]{2,})/, // Erreur de saisie, pas possible d'avoir plusieurs lettres
            // regex_incomplete_ueln_num: /(?<![0-9]+)([0-9]{9,13})(?![0-9]+)/, // Erreur de saisie, il manque un/des chiffres
            regex_incomplete_ueln_num: /^[0-9]{9,13}$/, // Erreur de saisie, il manque un/des chiffres
            // regex_incomplete_ueln_cle: /(?<![0-9]+)([0-9]{14})(?![A-Z]{1,})(?![0-9]+)/, // Erreur de saisie, il manque une lettre, la clé
            regex_incomplete_ueln_cle: /^[0-9]{14}$/, // Erreur de saisie, il manque une lettre, la clé
            regex_microship: /^[0-9]{15}$/,
            // eslint-disable-next-line no-useless-escape
            regex_email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

            regex_name_like: /([A-Za-z ]{5,})/, // regex permettant de commencer la recherche de nom

            regex_date_field: /[a-zA-Z]+_date/, // permet de catch un champs de bdd avec le terme _date dedans
            regex_licencekey: /([0-9A-Z]{4}-){4}([0-9A-Z]{4})/
        }
    },
    methods: {
        
    },
    computed: {
    	subDomainName() {
    		return getSubDomainName()
    	}
    }
})