import Constants from 'Constants'
import Store from '@/store/index'
import FileSaver from 'file-saver'
import Vue from 'vue'
import dateFormat from "dateformat"

import _get from 'lodash/get'
import _transform from 'lodash/transform'
import _isEqual from 'lodash/isEqual'
import _isPlainObject from 'lodash/isPlainObject'

export default class Common
{
	static cachedCurrencies = {}
	static timestampUniqueId = {
		// 12334535854125332: 0
	}

	constructor() {
		this.licence_key = null
		this.initGlobalTools()
		this.initConstants()
	}


	get_licencekey() {
		return this.licence_key;
	}

	initConstants() {
		Constants.IS_AND_RUNNING = Common.isAndroidRenderingContext()
	}

    static async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

	get_sire_decode(encoded_sire) {
		let decode = {};
		decode[0] = {"h": 0, "s": 1, "p" : 2, "" : 3, "m": 4, "t": 5, "n": 6, "x": 7, "q": 8, "w" :9};
		decode[1] = {"n": 0, "h": 1, "t": 2, "w": 3, "p": 4, "x": 5, "r": 6, "q": 7, "z": 8, "v": 9};
		decode[2] = {"q": 0, "p": 1, "x": 2, "n": 3, "r": 4, "z": 5, "t": 6, "m": 7, "k": 8, "h": 9};
		decode[3] = {"k": 0, "h": 1, "t": 2, "p": 3, "m": 4, "s": 5, "n": 6, "w": 7, "x": 8, "q": 9};
		decode[4] = {"r": 0, "b": 1, "v": 2, "s": 3, "k": 4, "j": 5, "h": 6, "q": 7, "m": 8, "x": 9};
		decode[5] = {"d": 0, "s": 1, "m": 2, "l": 3, "k": 4, "j": 5, "t": 6, "f": 7, "c": 8, "n": 9};
		decode[6] = {"p": 0, "l": 1, "n": 2, "r": 3, "t": 4, "s": 5, "d": 6, "f": 7, "m": 8, "k": 9};
		decode[7] = {"v": 0, "b": 1, "c": 2, "s": 3, "z": 4, "r": 5, "p": 6, "l": 7, "j": 8, "h": 9};

		let sire = "";
		let i = 0;
		let j = 0;

		// cheval de trait
		if(encoded_sire.length == 6) {
			sire += encoded_sire[0];
			sire += encoded_sire[1];

			for(i=2; i<6; i++) {
				j = encoded_sire[i];

				if (decode[i][j] != null) {
					sire += decode[i][j];
				}
			}
		}

		// cheval non trait
		if(encoded_sire.length == 8) {

			for(i=0; i<8; i++) {
				j = encoded_sire[i];
				if (decode[i][j] != null) {
					sire += decode[i][j];
				}
			}
		}
		return sire;
	}

	static get_sire_encode(sire) {
		let encode = {}

		encode[0] = {0: "h", 1: "s", 2: "p", 3: "", 4: "m", 5: "t", 6: "n", 7: "x", 8: "q", 9: "w"}
		encode[1] = {0: "n", 1: "h", 2: "t", 3: "w", 4: "p", 5: "x", 6: "r", 7: "q", 8: "z", 9: "v"}
		encode[2] = {0: "q", 1: "p", 2: "x", 3: "n", 4: "r", 5: "z", 6: "t", 7: "m", 8: "k", 9: "h"}
		encode[3] = {0: "k", 1: "h", 2: "t", 3: "p", 4: "m", 5: "s", 6: "n", 7: "w", 8: "x", 9: "q"}
		encode[4] = {0: "r", 1: "b", 2: "v", 3: "s", 4: "k", 5: "j", 6: "h", 7: "q", 8: "m", 9: "x"}
		encode[5] = {0: "d", 1: "s", 2: "m", 3: "l", 4: "k", 5: "j", 6: "t", 7: "f", 8: "c", 9: "n"}
		encode[6] = {0: "p", 1: "l", 2: "n", 3: "r", 4: "t", 5: "s", 6: "d", 7: "f", 8: "m", 9: "k"}
		encode[7] = {0: "v", 1: "b", 2: "c", 3: "s", 4: "z", 5: "r", 6: "p", 7: "l", 8: "j", 9: "h"}

		let code = ""
		let i = 0
		let j = 0

		// Cheval de trait
		if(sire.length == 6) {
			code += sire[0]
			code += sire[1]
			for(i=2; i<6; i++) {
				j = sire[i]

				if (encode[i][j] != null) {
					code += encode[i][j]
				}
			}
		}

		// Cheval non trait
		if(sire.length == 8) {
			for(i=0; i<8; i++) {
				j = sire[i]

				if (encode[i][j] != null) {
					code += encode[i][j]
				}
			}
		}

		return code
	}

	static async asyncForEachV2(array, callback) {
		for (let index = 0; index < array.length; index++) {
			callback(array[index], index, array);
		}
	}

	static async asyncForEachObject(object, callback) {
		return Common.asyncForEach(Object.keys(object), async (key) => {
			await callback(object[key], key, object)
		})
	}

	/*
		tpl => url dans Constants
		variables => dico, remplace les {var}
	 */
	static urlConstruct(tpl, variables) {
		// On découpe le tpl d'url, pour remplacer les variables
		let tab_tpl = tpl.split(/[{}]+/)

		// Tant qu'on a des variables non remplacées
		for (let key in variables) {
			const index = tab_tpl.indexOf()
			tab_tpl[index] = variables[key]
		}

		return tab_tpl.join('')
	}

	static truncateFloat(float, decimal=2) {
		const str = float.toString()
		if(str.indexOf('.') == -1)
		{
			return str.toFloat()
		}
		return str.substring(0, str.indexOf('.') + (1 + decimal)).toFloat()
	}

	static normalizeValue(val) {
		if (val instanceof Date) {
			const date_lang_format = Vue.prototype.$i18n.locale()
			val = Date.parseTz(val).toLocaleDateString(date_lang_format) 
		}
		else if (typeof val === 'string') {
			val = val.toUpperCase().replace(",", ".")

			// A laisser ici car les dates sont des nombres
			if (val && !isNaN(val)) {
				val = parseFloat(val)
			}
		}

		return val == null ? '' : val
	}

	static cleanValue(val) {
		if (typeof val === 'string') {
			val = val.replace(/[^a-zA-Z0-9,-]/g, '').toUpperCase().replace(",", ".")

			// A laisser ici car les dates sont des nombres
			if (val && !isNaN(val)) {
				val = parseFloat(val)
			}
		}

		return val || ''
	}

	initGlobalTools() {
		function compareValues(a, b) {
			if (a < b) {
				return -1;
			}
			else if (a > b) {
				return 1;
			}
			else {
				return 0;
			}
		}

		function compareObjects(a, b, field, direction) {
			a = Common.cleanValue(Common.getNestedObjectString(a, field))
			b = Common.cleanValue(Common.getNestedObjectString(b, field))

			return compareValues(a, b, direction)
		}

		// Sort array of objects by key
		if (!Array.prototype.sortBy) {
			Object.defineProperty(Array.prototype, 'sortBy', {
				value: function(field, direction='ASC') {
					const sorter = (a, b) => {
						const compareValue = compareObjects(a, b, field)
						if (direction === 'ASC') {
							return compareValue
						}
						else {
							return compareValue * -1
						}
					}
	
					return this.sort(sorter)
				}
			})
		}

		// Check if an array is sorted
		if (!Array.prototype.isSorted) {
			Object.defineProperty(Array.prototype, 'isSorted', {
				value: function(field, direction='ASC') {
					if (this.length === 0) {
						return true
					}

					let isSorted = true
					const directionValue = direction === 'ASC' ? -1 : 1
					let prev = this[0]
					if (prev[field] === undefined) {
						return true
					}

					this.forEach(function(next) {
						let compareVal = compareObjects(prev, next, field)
						if (compareVal !== 0 && compareVal !== directionValue) {
							isSorted = false
						}
						prev = next
					})

					return isSorted
				}
			})
		}

		/* Date au format YYYY-MM-DD, mais DD n'ayant qu'un digit, si le moins < 10 */
		if(!Date.prototype.toFormattedString) {
			Object.defineProperty(Date.prototype, 'toFormattedString', {
				value: function() {
					return this.getFullYear() + "-" + ('0' + (this.getMonth()+1)).slice(-2) + "-" + ('0' + this.getDate()).slice(-2)
				}
			})
		}

		Object.defineProperty(Date.prototype, 'toISOString', {
			value: function() {
				return this.toFormattedString()
			},
		})

		Object.defineProperty(Date.prototype, 'toTimeString', {
			value: function() {
				return this.getHours().toString().padStart(2, '0') +
				':' + this.getMinutes().toString().padStart(2, '0') +
				':' + this.getSeconds().toString().padStart(2, '0')
			}
		})

		if (!Date.parseTz) {
			Object.defineProperty(Date, 'parseTz', {
				value: function(str) {
					if (!str) {
						return new Date()
					}

					const parsed = new Date(str);
					if (!isNaN(parsed.getTime())) {
						return parsed
					}

					return new Date(str.replace(/-/g, '/').replace(/[a-z]+/gi, ' '))
				}
			})
		}

		/* Date au format YYYY-MM-DD classique */
		if(!Date.prototype.toDateInputValue) {
			Date.prototype.toDateInputValue = (function() {
			    var local = new Date(this)
			    return local.toJSON().slice(0,10)
			})
		}

		if(!Date.prototype.diffWithDate) {
			Date.prototype.diffWithDate = function(other_date) {
				const diffTime = this.getTime() - other_date.getTime()
				return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
			}
		}

		if(!Date.prototype.addDays) {
			Date.prototype.addDays = function(days) {
			    var date = new Date(this.valueOf());
			    date.setDate(date.getDate() + days);
			    return date;
			}
		}

		if(!String.prototype.validDate) {
			String.prototype.validDate = function() {
				/*
					Valid formats:

					- M/D/YYYY
					- M/DD/YYYY
					- MM/D/YYYY
					- MM/DD/YYYY
					- YYYY-M-D
					- YYYY-M-DD
					- YYYY-MM-D
					- YYYY-MM-DD
				*/

				let day = null
				let month = null
				let year = null
				let isLeapYear = false
				let monthLength = null
				let isISO8601 = null

				if (!/^(\d{1,2}\/\d{1,2}\/\d{4,}|\d{4,}-\d{1,2}-\d{1,2})$/.test(this)) {
					return false;
				}

				isISO8601 = /^\d{4}-\d{1,2}-\d{1,2}$/.test(this);

				// Get the month, day, and year parts
				let parts = this.split(isISO8601 ? "-" : "/");
				month = parseInt(parts[isISO8601 ? 1 : 0], 10);
				day = parseInt(parts[isISO8601 ? 2 : 1], 10);
				year = parseInt(parts[isISO8601 ? 0 : 2], 10);

				// Should be a valid javascript month 0-11
				if (month === 0 || month > 12) {
					return false;
				}

				// Valid month lengths
				monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

				// Check for leap years
				if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
					isLeapYear = true;
					monthLength[1]++;
				} else {
					isLeapYear = false;
				}

				return day > 0 && day <= monthLength[month - 1];
			}
		}

		if(!String.prototype.isValidDateTime) {
			Object.defineProperty(String.prototype, 'isValidDateTime', {
				value: function() {
					return this !== '0000-00-00' && this.match(/^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}:\d{2}){0,1}$/)
				}
			})
		}

		if (!String.prototype.toFloat) {
			Object.defineProperty(String.prototype, 'toFloat', {
				value: function() {
					let value = this.replace(/,/gm, ".")
					value = value.replace(/\s/gm, "")
					// Ce caractère n'est pas un espace mais un séparateur de milliers
					value = value.replace(/ /gm, "")
					return parseFloat(value)
				}
			})
		}

		if (!String.prototype.regexIndexOf) {
			String.prototype.regexIndexOf = function(regex, startpos=0) {
				const indexOf = this.substring(startpos || 0).search(regex)
				return (indexOf >= 0) ? (indexOf + (startpos || 0)) : indexOf
			}
		}

		if (!Blob.prototype.arrayBuffer) {
			Object.defineProperty(Blob.prototype, 'arrayBuffer', {
				value: function() {
					return new Promise((resolve) => {
						let fr = new FileReader()
						fr.onload = () => {
							resolve(fr.result)
						}
						fr.readAsArrayBuffer(this)
					})
				}
			})
		}
	}

	static base64ToPdf(base64, name) {
		var byteCharacters = atob(base64);
		var byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
		    byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		var byteArray = new Uint8Array(byteNumbers);
		var blob = new Blob([byteArray], { type: "application/pdf" });

		FileSaver.saveAs(blob, name);
	}

	static base64toBlob(base64Data, name) {
	    let contentType = '';
	    let sliceSize = 1024;
	    let byteCharacters = atob(base64Data);
	    let bytesLength = byteCharacters.length;
	    let slicesCount = Math.ceil(bytesLength / sliceSize);
	    let byteArrays = new Array(slicesCount);

	    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
	        let begin = sliceIndex * sliceSize;
	        let end = Math.min(begin + sliceSize, bytesLength);

	        let bytes = new Array(end - begin);
	        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
	            bytes[i] = byteCharacters[offset].charCodeAt(0);
	        }
	        byteArrays[sliceIndex] = new Uint8Array(bytes);
	    }
	    let blob = new Blob(byteArrays, { type: contentType });
	    FileSaver.saveAs(blob, name);
	}

	static csvToFile(csv, name){
		var blob = new Blob([csv]);
		if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
		    window.navigator.msSaveBlob(blob, name);
		else
		{
		    var a = window.document.createElement("a");
		    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
		    a.download = name;
		    document.body.appendChild(a);
		    a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
		    document.body.removeChild(a);
		}
	}

	static downloadJSON(json, name='download.json') {
		const str = JSON.stringify(json)
		const bytes = new TextEncoder().encode(str);
		const blob = new Blob([bytes], {
			type: "application/json;charset=utf-8"
		})
		FileSaver.saveAs(blob, name)
	}

	static waitUntil(cb, timeout=20000, interval=10) {
		const timeoutError = new Error('Wait until timed out')
		return new Promise((resolve, reject) => {
			let count = 0
			const checker = () => {
				if (cb()) {
					resolve()
				}
				else if (timeout / interval < count) {
					reject(timeoutError)
				}
				else {
					setTimeout(checker, interval)
				}
				count++
			}
			checker()
		})
	}

	static findWithAttr(array, attr, value) {
	    for(var i = 0; i < array.length; i += 1) {
	        if(array[i][attr] === value) {
	            return i;
	        }
	    }
	    return -1;
	}

    /**
     * @param {Number} max
     * @return Nombre aléatoire entre 0 et {max}
     */
    static getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max))
    }

	/* Ne foncitonne pas pour les tests d'apple, personne ne sait pourquoi */
    static getActualPlatform() {
        const userAgent = window.navigator.userAgent.toLowerCase()
        const safari = /safari/.test(userAgent)
        const ios = /iphone|ipod|ipad/.test(userAgent)

        if(ios) {
            if (safari) { // browser through IOS
                return "ios:safari"
            } 
            else { // wkwebview
                return "ios:wkwebview"
            }
        } 
        else { // not iOS
            return "regular"
        }
	}
	
	static getActualPlatformEvo() {
		if(Constants.IS_IOS_RUNNING) return "ios:wkwebview"
		else if(Constants.IS_AND_RUNNING) return "android"
		return "regular"
	}

	static isAndroidRenderingContext() {
		// Si on est exécuté dans une webview android, on doit avoir la variable globale Android
		try {
			return typeof(Android) != "undefined"
		}
		catch(e) {
			return false
		}
	}

	static getRandomString(length) {
		const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
		const charactersLength = characters.length;
		let result           = '';
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	static getNegativeId() {
		// Si on a déjà un random pour ce
		const now = (new Date()).getTime()
		let lastIncrement = Common.timestampUniqueId[now] || 0
		Common.timestampUniqueId[now] = ++lastIncrement

		return -parseInt(`${now}${lastIncrement.toString().padStart(3, '0')}`)
	}

	static async getCurrency(currencyCode) {
		return {
			currency_code: "EUR",
			currency_decimal_digits: 2,
			currency_id: 3,
			currency_name: "Euro",
			currency_name_plural: "euros",
			currency_symbol: "€"
		}
	}

	static async priceFormat(val, currencyCode='EUR', withSymbol=false) {
		const currency = await Common.getCurrency(currencyCode)
		const lang = Store.state.user.lang

		val = val.toString().replace(',', '.')

		if(val.indexOf(".") > -1 )
		{
			val = val.substring(0, val.indexOf(".") + 3)
		}

		const formatter = new Intl.NumberFormat(lang, {
			style: withSymbol ? 'currency' : 'decimal',
			currency: currencyCode,
			minimumFractionDigits: currency.currency_decimal_digits,
			maximumFractionDigits: currency.currency_decimal_digits
		})

		return formatter.format(val)
	}

	static getNestedObjectString(nestedObj, pathArr) {
		return _get(nestedObj, pathArr)
	}

	static getArrayObjProperty(tab, property) {
		return tab.map(obj => {
			return Common.getNestedObjectString(obj, property);
		})
	}

	/* Permet de construire une route avec les bon params */
	/* ur_base (String) => l'url avec les params à remplacer */
	/* params (Object) => les params avec en clé le param de la route, en valeur le param à donner */
	static constructRoute(url_base, params) {
		Object.keys(params).forEach(function(key) {
			if(url_base.indexOf(key) > -1) {
				url_base = url_base.replace(":"+key, params[key])
			}
		})

		return url_base
	}


	/**
	 * Deep diff between two object, using lodash
	 * @param  {Object} object Object compared
	 * @param  {Object} base   Object to compare with
	 * @return {Object}        Return a new object who represent the diff
	 */
	static difference(object, base) {
		function changes(object, base) {
			return _transform(object, function(result, value, key) {
				if (!_isEqual(value, base[key])) {
					result[key] = (_isPlainObject(value) && _isPlainObject(base[key])) ? changes(value, base[key]) : value
				}
			})
		}

		return changes(object, base);
	}

	/* Retourne un flat array en se basant sur une clé donnée */
	static flattenBy(arr, propName, trackList=[]) {
		arr.forEach(a => {
			trackList.push(a)

			const toCheckRecur = a[propName]
			if(toCheckRecur) {
				if(Array.isArray(toCheckRecur)) {
					const temp = Common.flattenBy(toCheckRecur, propName)
					trackList = trackList.concat(temp)
				}
				else {
					trackList.push(toCheckRecur)
				}
			}
		})

		return trackList
	}

	static manageCivilities(civility) {
		let unformatted_civility = ''
		if(civility == Vue.i18n.translate('civility.m')) {
			unformatted_civility = 'm'
		}
		else if(civility == Vue.i18n.translate('civility.mme')) {
			unformatted_civility = 'mme'
		}
		else if(civility == Vue.i18n.translate('civility.dr')) {
			unformatted_civility = 'dr'
		}
		else if(civility == Vue.i18n.translate('civility.cte')) {
			unformatted_civility = 'cte'
		}
		else if(civility == Vue.i18n.translate('civility.ctesse')) {
			unformatted_civility = 'ctesse'
		}
		else if(civility == Vue.i18n.translate('civility.pr')) {
			unformatted_civility = 'pr'
		}
		else if(civility == Vue.i18n.translate('civility.me')) {
			unformatted_civility = 'me'
		}
		else if(civility == Vue.i18n.translate('civility.aud')) {
			unformatted_civility = 'aud'
		}
		else if(civility == Vue.i18n.translate('civility.vte')) {
			unformatted_civility = 'vte'
		}
		else if(civility == Vue.i18n.translate('civility.vtesse')) {
			unformatted_civility = 'vtesse'
		}
		else if(civility == Vue.i18n.translate('civility.m_mme')) {
			unformatted_civility = 'm_mme'
		}

		return unformatted_civility
	}

	static getAllCivilities() {
		return [
			Vue.i18n.translate('civility.m'),
			Vue.i18n.translate('civility.mme'),
			Vue.i18n.translate('civility.dr'),
			Vue.i18n.translate('civility.cte'),
			Vue.i18n.translate('civility.ctesse'),
			Vue.i18n.translate('civility.pr'),
			Vue.i18n.translate('civility.me'),
			Vue.i18n.translate('civility.aud'),
			Vue.i18n.translate('civility.vte'),
			Vue.i18n.translate('civility.vtesse'),
			Vue.i18n.translate('civility.m_mme'),
			Vue.i18n.translate('civility. ')
		]
	}

	static translateSeasonMareStatus(status) {
		let translated_status = status
		if(translated_status === undefined) {
			return ''
		}
		if(translated_status.includes('Non saillie')) {
			translated_status = translated_status.replace('Non saillie', Vue.i18n.translate('monte.status.non_saillie'))
		}
		if(translated_status.includes('Transplantée')) {
			translated_status = translated_status.replace('Transplantée le', Vue.i18n.translate('monte.status.transplantee'))
		}
		if(translated_status.includes('Saillie')) {
			translated_status = translated_status.replace('Saillie le', Vue.i18n.translate('monte.status.saillie'))
		}
		if(translated_status.includes('DG++')) {
			translated_status = translated_status.replace('DG++ le', Vue.i18n.translate('monte.status.dg_plusplus'))
		}
		if(translated_status.includes('DG+')) {
			translated_status = translated_status.replace('DG+ le', Vue.i18n.translate('monte.status.dg_plus'))
		}
		if(translated_status.includes('DG-')) {
			translated_status = translated_status.replace('DG- le', Vue.i18n.translate('monte.status.dg_moins'))
		}
		if(translated_status.includes('DG?')) {
			translated_status = translated_status.replace('DG? le', Vue.i18n.translate('monte.status.dg_unknown'))
		}
		if(translated_status.includes('Avortée')) {
			translated_status = translated_status.replace('Avortée le', Vue.i18n.translate('monte.status.avortee'))
		}
		if(translated_status.includes('Coulée')) {
			translated_status = translated_status.replace('Coulée le', Vue.i18n.translate('monte.status.coulee'))
		}
		if(translated_status.includes('DG inconnu')) {
			translated_status = translated_status.replace('DG inconnu le', Vue.i18n.translate('monte.status.dg_inconnu'))
		}
		if(translated_status.includes('Suitée')) {
			translated_status = translated_status.replace('Suitée', Vue.i18n.translate('monte.status.suitee'))
			.replace(' de ', Vue.i18n.translate('monte.status.de')) 
		}
		if(translated_status.includes('Poulinage')) {
			translated_status = translated_status.replace('Poulinage', Vue.i18n.translate('monte.status.poulinage'))
		}
		if(translated_status.includes('Maiden début de saison')) {
			translated_status = translated_status.replace('Maiden début de saison', Vue.i18n.translate('monte.status.maiden_debut'))
		}
		else if(translated_status.includes('Maiden')) {
			translated_status = translated_status.replace('Maiden', Vue.i18n.translate('monte.status.maiden'))
		}
		if(translated_status.includes('Morte')) {
			translated_status = translated_status.replace('Morte le', Vue.i18n.translate('monte.status.morte'))
		}
		if(translated_status.includes('Mort né')) {
			translated_status = translated_status.replace('Mort né', Vue.i18n.translate('monte.status.mort_ne'))
		}
		if(translated_status.includes('Poulain mort')) {
			translated_status = translated_status.replace('Poulain mort le', Vue.i18n.translate('monte.status.morte'))
		}
		if(translated_status.includes('PV')) {
			translated_status = translated_status.replace('PV', Vue.i18n.translate('monte.status.pv'))
		}
		if(translated_status.includes('Collectée le')) {
			translated_status = translated_status.replace('Collectée le', Vue.i18n.translate('monte.status.collectee'))
		}

		let match = translated_status.match(/[0-9]*\/[0-9]*\/[0-9]*/gm)
		// let match_date = match ? match[0]: ''
		// let split = match_date.split('/')

		// if(Vue.i18n.locale() === 'en' && split.length > 1) {
		// 	translated_status = translated_status.replace(match_date, split[1]+'/'+split[0]+'/'+split[2])
		// }

		let date_config = JSON.parse(localStorage.getItem('format_date')) ?? 'd/m/Y'

		if(date_config && match) {
			date_config = date_config.replace('y', 'yy').replace('Y', 'yyyy').replace('d', 'dd').replace('m', 'mm')
			for(let i in match) {
				let split = match[i].split('/')
				translated_status = translated_status.replace(match[i], dateFormat(Date.parseTz(split[1]+'/'+split[0]+'/'+split[2]), date_config))
			}
		}


		return translated_status
	}
}
