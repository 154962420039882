/*
METAS :
	accessRights: String => Code de droit d'accès (par ex, "MVP", ou "TRIGG")
	recurAccessRights: Boolean[default=false] => Indique que le accessRights prend également effet sur les routes enfants
	noLoggedIn: Boolean[default=false] => Indique que cette route est accessible uniquement sans authentification
	canAccessLoggedIn: Boolean[default=false] => Indique que la route est accessible qu'on soit connecté ou non
	needLoading: Boolean[default={noLoggedIn ? false : true}] => Indique que le route a besoin de passer par le chargement
*/

export default {
	routes: [
		{
			path: "/",
			name: "Home",
			component: () => import("@/views/Home"),
			meta: {
				noLoggedIn: true
			}
        },
		{
			path: "/home",
			name: "HomeMenu",
			component: () => import("@/views/HomeMenu"),
			meta: {
				accessRights: "MVP"
			}
        },
		{
			path: "/compte_creation",
			name: "CompteCreation",
			component: () => import("@/views/CompteCreation"),
			props: (route) => ({cp: route.query.cp}),
			meta: {
				noLoggedIn: true,
			}
		},
		{
			path: "/ask_reset_password",
			name: "askResetPassword",
			component: () => import("@/views/AskResetPassword"),
			meta: {
				noLoggedIn: true,
			}
		},
        {
			path: "/loading",
			name: "Loading",
			component: () => import("@/views/LoadingScreen"),
			props: true
        },
        {
			path: "/horse",
			name: "horseListe",
			component: () => import("@/views/HorseListe"),
			meta: {
				accessRights: "CAHRS"
			}
		},
        {
			path: "/mare",
			name: "mareListe",
			component: () => import("@/views/MareListe"),
			meta: {
				accessRights: "CAMARE"
			}
		},
		{
			path: "/acte_liste",
			name: "acteListe",
			component: () => import("@/views/ActeListe"),
			meta: {
				accessRights: "CAACTE"
			}
		},
		{
			path: "/horse_ajout/",
			name: "horseAjout",
			component: () => import("@/views/HorseAjoutBeta"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			}
		},
		{
			path: "/horse_creation",
			name: "horseCreation",
			component: () => import("@/views/HorseCreation"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			}
		},
		{
			path: "/horse/:horse_id",
			name: "horseFiche",
			component: () => import("@/views/HorseFiche"),
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "horse_informations/",
					name: "HorseFicheInfo",
					component: () => import("GroomyRoot/components/Horse/InformationsHorse"),
					props: true,
					meta: {
						accessRights: "MVP"
					}
				},
                {
					path: "horse_informations_tiny/",
					name: "HorsePedigreePdf",
					component: () => import("@/components/Dedicated/Horse/InformationsHorse"),
					props: true,
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "horse_actes/",
					name: "HorseFicheActes",
					component: () => import("@/components/Horse/HorseActes"),
					props: true,
					meta: {
						accessRights: "CAACTE"
					}
				},
                {
					path: "horse_notes/",
					name: "HorseFicheNotes",
					component: () => import("GroomyRoot/components/Notes/List"),
                    props: (route) => ({
                        horse_id: route.params.horse_id,
                        is_client_area: true
                    }),
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "monte_jument/",
					name: "ContractMareList",
					component: () => import("@/components/Horse/ContractMare"),
					props: true,
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "horse_product/",
					name: "HorseProduct",
					component: () => import("@/components/Horse/HorseProduct"),
					props: true,
					meta: {
						accessRights: "MVP",
						onlineOnly: true
					}
				},
                {
					path: "horse_product_dedicated/",
					name: "HorseProductDedicated",
					component: () => import("@/components/Dedicated/Horse/HorseProduct"),
					props: true,
					meta: {
						accessRights: "MVP",
						onlineOnly: true
					}
				},
                {
                    path: "horse_feed/",
                    name: "HorseFeed",
                    component: () => import("@/components/Dedicated/Notes/Feed"),
					props: true,
					meta: {
						accessRights: "CAFLA",
						onlineOnly: true
					}
                },
                {
                    path: "horse_monte_infos/",
                    name: "HorseMonteInfo",
                    component: () => import("@/components/Dedicated/Horse/InfosMonte"),
					props: true,
					meta: {
						accessRights: "CAMI",
						onlineOnly: true
					}
                },
                {
                    path: "horse_perfs/",
                    name: "HorsePerfs",
                    component: () => import("@/components/Dedicated/Horse/HorsePerfs"),
					props: true,
					meta: {
						accessRights: "CAPR",
						onlineOnly: true
					}
                },
                {
                	path: "pax/st=:stallion_ids?/fc=:filtre_categories?/fp=:filtre_prix?",
                    name: "HorsePax",
                    component: () => import("@/components/Horse/Pax"),
					props: true,
					meta: {
						accessRights: "CAPAX",
						onlineOnly: true
					}
                },
                {
                	path: "pax_suggestion/",
                    name: "HorsePaxSuggestion",
                    component: () => import("@/components/Horse/PaxSuggestion"),
					props: true,
					meta: {
						accessRights: "CAPAX",
						onlineOnly: true
					}
                },
                {
                	path: "croissance/",
                    name: "HorseSuiviCroissance",
                    component: () => import("@/components/Horse/HorseCroissance"),
					props: true,
					meta: {
						accessRights: "SVCROI",
						onlineOnly: true
					}
                },
                {
                	path: "croissance/cr=:croissance_id?",
                    name: "AddHorseCroissance",
                    component: () => import("@/components/Horse/AddHorseCroissance"),
					props: true,
					meta: {
						accessRights: "SVCROI",
						onlineOnly: true
					}
                },
                {
                	path: "croissance/compare",
                    name: "HorseCroissanceGraph",
                    component: () => import("@/components/Horse/HorseCroissanceGraph"),
					props: true,
					meta: {
						accessRights: "SVCROI",
						onlineOnly: true
					}
                }
			]
		},
		{
			path: "/addressed_invoices",
			name: "addressedInvoices",
			component: () => import("@/views/InvoicesAddressed"),
			meta: {
				accessRights: "CAFACT"
            }
        },
        {
			path: "/addressed_payments",
			name: "addressedPayments",
			component: () => import("@/views/PaymentsAddressed"),
			meta: {
				accessRights: "CAFACT|CAREGL"
            }
		},
		{
			path: "/addressed_quotations",
			name: "addressedQuotations",
			component: () => import("@/views/QuotationsAddressed"),
			meta: {
				accessRights: "CADEVIS"
            }
        },
        {
			path: "/payment_methods",
			name: "paymentMethods",
			component: () => import("@/views/PaymentMethods"),
			meta: {
				accessRights: "CAREGL",
                recurAccessRights: true
            },
            children: [
		        {
					path: "add/",
					name: "AddPaymentMethod",
					component: () => import("GroomyRoot/components/Marketplace/PaymentMethodsAdd"),
				},
				{
					path: "list/",
					name: "paymentMethodList",
					component: () => import("GroomyRoot/components/Marketplace/PaymentMethodsList"),
                    meta: {
                        accessRights: "CAREGL&MANG"
                    }
				},
            ]
		},
		{
			path: "/valid_email/tk=:token?/sg=:signature?/em=:email?/uid=:userid?",
			name: "EmailValidation",
			component: () => import("@/views/EmailValidation"),
			meta: {
				noLoggedIn: true,
				canAccessLoggedIn: true
			}
		},
        {
        	path: '/monte/contrats',
        	name: 'ContractList',
        	component: () => import('@/components/Contract/ContractList'),
			meta: {
				accessRights: "CASAILL"
			},
        },
        {
        	path: '/monte/contrats/:contract_id',
        	name: 'Contract',
        	component: () => import('@/components/Contract/ContractFiche'),
			meta: {
				accessRights: "CASAILL"
			},
        },
        {
        	path: '/monte/contrats/new/s_id=:stallion_id?/m_id=:mare_id?',
        	name: 'ContractNew',
        	component: () => import('@/components/Contract/ContractNew'),
			meta: {
				accessRights: "CASAILL"
			},
        },
        {
			path: "/notes",
			name: "notes",
			component: () => import("@/views/NotesListe"),
			meta: {
				accessRights: "CANOTE"
			},
		},
        {
			path: "/note_fiche/n_id=:note_id",
			name: "notesManagement",
			component: () => import("@/views/NoteFiche"),
			meta: {
				accessRights: "CANOTE"
			},
		},
        {
			path: "/feed",
			name: "feed",
			component: () => import("@/views/Dedicated/Feed"),
			meta: {
				accessRights: "CAFLA"
			},
		},
        {
			path: "/effectif",
			name: "effectif",
			component: () => import("@/views/Dedicated/Effectif"),
			meta: {
				accessRights: "CALE"
			},
		},
        {
			path: "/partants",
			name: "partants",
			component: () => import("@/views/Dedicated/Partants"),
			meta: {
				accessRights: "CAPR"
			},
		},
        {
			path: "/resultats",
			name: "resultats",
			component: () => import("@/views/Dedicated/Resultats"),
			meta: {
				accessRights: "CAPR"
			},
		},
		{
			path: "/display_settings",
			name: "displaySettings",
			component: () => import("@/views/DisplaySettings"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true,
			}
		},
		{
			path: "/switch_lang",
			name: "switchLang",
			component: () => import("@/views/SwitchLang"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true,
			}
		},
		{
			path: "/reset_password",
			name: "resetPassword",
			component: () => import("@/views/ResetPassword"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true,
			}
		},
		{
			path: "/pax/home",
			name: "paxHome",
			component: () => import("@/views/PaxHome"),
			meta: {
				accessRights: "CAPAX",
				onlineOnly: true,
			}
		},
		{
			path: "/pax/reverse",
			name: "paxReverse",
			component: () => import("@/views/PaxReverse"),
			meta: {
				accessRights: "CAPAX",
				onlineOnly: true
			}
		},
		{
			path: "/pax/suggestion",
			name: "paxSuggestion",
			component: () => import("@/views/PaxSuggestion"),
			meta: {
				accessRights: "CAPAX",
				onlineOnly: true
			}
		},
		{
			path: "/pax/st=:stallion_ids?/m_id=:mare_id",
			name: "pax",
			component: () => import("@/views/Pax"),
			meta: {
				accessRights: "CAPAX",
				onlineOnly: true,
			}
		},
		{
			path: "/expeditions",
			name: "expeditions",
			component: () => import("@/views/Expeditions"),
			meta: {
				accessRights: "DMNEXP",
				onlineOnly: true,
			}
		},
		{
			path: "/gocardless",
			name: "gocardless",
			component: () => import("@/views/Gocardless"),
			meta: {
				accessRights: "CAREGL&GOCAR",
				onlineOnly: true,
			},
            children: [
		        {
					path: "bank_account/",
					name: "gocardlessBankAccount",
					component: () => import("@/components/Gocardless/BankAccount"),
				},
				{
					path: "mandate/",
					name: "gocardlessMandate",
					component: () => import("@/components/Gocardless/Mandate"),
				},
				{
					path: "payment/",
					name: "gocardlessPayment",
					component: () => import("@/components/Gocardless/Payment"),
				},
            ]
		},
		{
			path: "/pax_express",
			name: "PaxExpress",
			component: () => import("@/views/PaxExpress"),
			meta: {
				noLoggedIn: true
			}
        },
    ]	
}
